.container {
  padding-left: var(--gallery-item-side-padding-px);
  padding-right: var(--gallery-item-side-padding-px);
  margin-bottom: var(--brick-tile-bottom-padding-px);
}

.image {
  width: 100%;
  vertical-align: top;
}

.imagePlaceholder {
  aspect-ratio: 2 / 1;
}

.guideItemContainer {
  margin-bottom: 0;
}

.guideItemImageWrapper {
  @media (--min-width-small) {
    width: 120px;
    height: 120px;
  }

  @media (--min-width-medium) {
    width: 80px;
    height: 80px;
  }
}
