.wideTextTile {
  width: 100%;
  position: relative;
  display: inline-block;
  border-radius: 4px;
  vertical-align: top;
  filter: drop-shadow(0 3px 3px rgb(28 31 58 / 18%));
}

.image {
  width: 100%;
  height: 100%;
}

.titleWrapper {
  width: 100%;
  height: 100%;
  top: 0;
  margin: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.clampTextWrapper {
  padding: 15px;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

.title {
  font-size: var(--font-size-4);
  line-height: 22px;
  max-height: 100%;
  max-width: 100%;

  @media (--min-width-small) {
    font-size: var(--font-size-4);
  }

  @media (--min-width-medium) {
    font-size: var(--font-size-5);
  }

  @media (--min-width-large) {
    font-size: var(--font-size-4);
  }

  @media (--min-width-xxlarge) {
    font-size: 22px;
  }
}
