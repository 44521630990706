.scheduleCardContainer {
  background-color: var(--content-area-background-color-hex);
  border-radius: 6px;

  [data-darkmode='true'] & {
    background-color: var(--secondary-color-5);
  }

  &.isDiscord {
    background-color: unset;
  }
}

.divider {
  border-radius: 6px;
  height: 1px;
  background-color: var(--elephant-grey);

  [data-darkmode='true'] & {
    color: var(--secondary-color-5);
    opacity: 0.1;
  }
}

.collapsibleLabelButtonContainer {
  margin-top: 16px;
}
