.menuOption {
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 16px;
  flex-wrap: nowrap;
  cursor: pointer;
  border-top: 1px solid var(--elephant-grey);

  &.isFirstOption {
    border-top: none;
    border-radius: 8px 8px 0 0;
  }

  &.isLastOption {
    border-radius: 0 0 8px 8px;
  }

  > p {
    font-size: var(--font-size-2);
    line-height: 19px;
    white-space: nowrap;
    margin-right: auto;
    color: var(--secondary-color-5);

    [data-darkmode='true'] & {
      color: white;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--grey-poupon);
    }
  }
}

.iconClassName {
  width: var(--medium-icon-size);
  height: var(--medium-icon-size);
}
