.link {
  composes: link from '../../../styles/common.module.scss';
  display: inline-block;
  color: var(--secondary-color-5);

  [data-darkmode='true'] & {
    color: var(--white);
  }

  &:hover {
    .chevronRight {
      margin-left: 16px;
    }
  }
}

.chevronRight {
  display: inline-block;
  margin-left: 8px;
  transition: var(--base-transition);
}

.chevronRightBackground {
  background: transparent url('/assets/img/shared/chevron-right.svg') no-repeat;
  background-size: contain;
}

.chevronRightBoldBackground {
  background: transparent url('/assets/img/shared/chevron-right-bold.svg')
    no-repeat;
  background-size: contain;
}

.chevronWrapper {
  display: inline-block;
}
