.container {
  position: absolute;
  z-index: 1;
  border-radius: 8px;
  box-shadow: 0 0 12px rgb(28 31 58 / 18%);
  cursor: default;
}

.isClosed {
  display: none;
}

.content {
  position: relative;
  z-index: 3;
  background-color: var(--content-area-background-color-hex);
  border-radius: 8px;

  :global(html.discord) & {
    background-color: var(--medium-grey);
  }
}

.closeIcon {
  top: 12px;
  right: 12px;
  z-index: 5;
  height: 12px;
  width: 12px;
}
