.flowContainer {
  /* height of 4 tag cells (39px + 9px margin) * 4 + 9px for neg margin */
  max-height: 201px;
  max-width: calc(
    100% + 16px
  ); /* combined with -9px margin below to get TagCell close to edge */

  overflow: hidden;

  /* removing margin from around the edges of the TagCells */
  margin-top: -9px;
  margin-right: -9px;
}

.Large {
  /* height of 6 tag cells (39px + 16x margin) * 6 + 16px for neg margin */
  max-height: 346px;

  @media (--min-width-small) {
    /* height of 3 tag cells (39px + 16x margin) * 3 + 16px for neg margin */
    max-height: 163px;
  }
}

.Small {
  @media (--min-width-small) {
    /* height of 2 tag cells (39px + 16x margin) * 2 + 16px for neg margin */
    max-height: 126px;
  }
}
