.scheduleItemContainer {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--secondary-color-5);

  [data-darkmode='true'] & {
    color: var(--white);
  }
}

.dateTime {
  display: inline-block;
  font-size: var(--font-size-2);
  line-height: 19px;
  min-width: 50px;
}

.title {
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
  font-size: var(--font-size-2);
  line-height: 19px;
  flex-grow: 1;
}

.titleWithoutDate {
  font-weight: bold;
  font-size: var(--font-size-3);
  line-height: 21px;
  margin-left: 0;
}
