.container {
  margin-top: var(--default-margin-px);
}

.link {
  composes: link from '../../../styles/common.module.scss';
  display: flex;
  flex-direction: column;
  align-items: center;
  float: left;
  color: var(--secondary-color-5);
  font-weight: bold;
  line-height: 16px;
  font-size: var(--font-size-1);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-loose);
  height: 36px;
  cursor: pointer;

  [data-darkmode='true'] & {
    color: var(--white);
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--cloud-grey);
  }

  &:hover {
    .caret {
      margin-top: 8px;
    }
  }
}
