.arrowIcon {
  display: inline-block;
  width: var(--medium-icon-size);
  height: var(--medium-icon-size);

  [data-darkmode='true'] & {
    path {
      stroke: var(--white);
    }
  }
}
