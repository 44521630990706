.container {
  margin-inline: -12px;
  overflow: scroll hidden;
  -webkit-overflow-scrolling: touch;

  :global(html.discord) & {
    scrollbar-width: none; /* hide scrollbar in Firefox */

    /* hide scrollbar in WebKit browsers */
    &::-webkit-scrollbar {
      appearance: none;
      width: 0;
      height: 0;
    }
  }

  /* temporary fix for ford: 
    this pulls the carousel to the edge for the peek feature
    and then fixes the vertical alignment */
  :global(html.ford) & {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 6px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -16px;
      width: 10%;
      height: 100%;
      background: linear-gradient(to right, transparent, #26282B);
      pointer-events: none; /* Ensures the overlay does not interfere with interactions */
      z-index: 2;
    }
  }

  @media (--min-width-small) {
    &:not(.enableHorizontalScroll) {
      overflow-x: hidden;
    }
  }
}

.row {
  margin-bottom: 16px;
}

.row:last-child {
  margin-bottom: 0;
}

.galleryTitle {
  margin-bottom: var(--gallery-content-sub-header-spacing);
}

.carousel {
  margin-block: 7px;
  white-space: nowrap;
  position: relative;
  left: 0%;
  transition: left var(--gallery-page-transition-delay) + ms ease-out;
}



.item {
  display: inline-block;
  vertical-align: top;
}

.header {
  composes: contentSubHeader from '../container-items.module.scss';
  margin-bottom: var(--gallery-content-sub-header-spacing);
}

.relative {
  position: relative;
}

.pager {
  display: none;
  position: absolute;
  top: 0;
  transition: var(--base-transition);
  width: 24px;
  border-radius: 2px;
  overflow: hidden;
  bottom: 28px;
  background-color: transparent;
  height: calc(100% - 28px);

  &.noBottomTile {
    height: 100%;
  }

  @media (--min-width-small) {
    display: block;

    &.right {
      right: -31px;
    }
  }

  &.left {
    left: -31px;
  }

  &.hide {
    display: none;
  }
}

.desktopPager {
  [data-darkmode='true'] & {
    background-color: var(--dark-blue);

    &:hover {
      cursor: pointer;
      background-color: var(--dark-blue);
    }
  }
}

.tableContainer {
  height: 100%;
}

.chevron {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 9px;
  height: 14px;
  transform: translate(-50%, -50%);
}

.chevronRight {
  background: transparent url('/assets/img/shared/chevron-right.svg') no-repeat;
  background-size: contain;

  [data-darkmode='true'] & {
    background: transparent url('/assets/img/shared/white-chevron-right.svg')
      no-repeat;
  }

  &:hover {
    cursor: pointer;
  }
}

.chevronLeft {
  background: transparent url('/assets/img/shared/chevron-left.svg') no-repeat;
  background-size: contain;

  [data-darkmode='true'] & {
    background: transparent url('/assets/img/shared/white-chevron-left.svg')
      no-repeat;
  }

  &:hover {
    cursor: pointer;
  }
}

.carouselItemContainer {
  margin: 0;
  padding: 0 4px;

  @media (--min-width-small) {
    padding: 0 8px;
  }
}
