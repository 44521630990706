@use 'sass:math';

.matrixOverlay {
  width: 100%;
}

.selectableOverlay {
  $totalMarginRight: 24px;

  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  &:not(:nth-child(3n)) {
    margin-right: math.div($totalMarginRight, 2);
  }

  > div {
    padding: 0;
  }
}

.tileContainer {
  padding-left: var(--gallery-item-side-padding-px);
  padding-right: var(--gallery-item-side-padding-px);

  /* temporary fix for ford */
  :global(html.ford) & {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.image {
  border-radius: 3px;
  position: relative;
  vertical-align: top;
  width: 100%;
  background-color: white;
  z-index: 0;
  aspect-ratio: 1;

  :global(html.discord) & {
    background-color: transparent;
  }
}

.captionContainer {
  padding-top: 8px;
  display: block;
  text-align: left;
  font-size: var(--font-size-1);
  overflow: hidden;
  color: var(--secondary-color-5);

  /* temporary fix for ford */
  :global(html.ford) & {
    font-size: var(--font-size-2);
  }

  /* TITLE/SUBTITLE ALIGNMENT */
  &.textAlignCenter {
    text-align: center;
  }

  &.textAlignLeft {
    text-align: left;
  }

  &.textAlignRight {
    text-align: right;
  }
}
