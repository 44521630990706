.tagCell {
  width: 100%;
  height: 100%;
  font-size: var(--font-size-2);
  color: var(--secondary-color-5);
  user-select: none;
  line-height: 19px;
  text-transform: capitalize;

  &.toggleSelected {
    color: var(--content-area-background-color-hex);
  }

  [data-darkmode='true'] & {
    color: var(--white);
  }
}

.disabled {
  cursor: default;
  opacity: 0.6;
}

.tagCellContainer {
  display: inline-block;
  margin: 7px 9px 0 0;
  max-width: 100%;
  height: 39px;
}

.linkContainer {
  padding: 9px 21px;
  border-radius: 54px;
  border: solid 1px var(--elephant-grey);
  background-color: var(--content-area-background-color-hex);
  overflow: hidden;
  transition:
    border 0.5s,
    background-color 0.5s;

  &:hover {
    background-color: var(--grey-poupon);
  }

  :global(html.discord) & {
    border: solid 1px rgb(255 255 255 / 20%);

    &:hover {
      background-color: rgb(255 255 255 / 20%);
    }
  }

  &.toggleTagCell {
    cursor: pointer;
  }

  &.toggleSelected {
    background-color: var(--secondary-color-5);
  }

  .disabled &:hover {
    border: solid 1px var(--elephant-grey);
    background-color: var(--content-area-background-color-hex);
    cursor: default;
  }
}
