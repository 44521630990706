/* Entire NumberedLink Guide Item container */
$numbered-link-container-small: 133px;
$numbered-link-container-medium: 65px;
$numbered-link-container-xxlarge: 91px;

.numberedLinkContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
  margin-bottom: 8px;
  width: 100%;
  height: 70px;

  @media (--min-width-small) {
    height: $numbered-link-container-small;
    margin-bottom: 16px;
  }

  @media (--min-width-medium) {
    height: $numbered-link-container-medium;
  }

  @media (--min-width-xxlarge) {
    height: $numbered-link-container-xxlarge;
  }

  :global(html.ford) & {
    height: auto;
  }

  &:hover {
    h2 {
      color: var(--cloud-grey);
    }
  }

  &.noMarginBottom {
    margin-bottom: 0;
  }

  /* Guide Item Image Wrapper */
  .numberedLinkImageContainer {
    width: 68px;
    height: 67px;
    display: inline-block;

    @media (--min-width-small) {
      width: $numbered-link-container-small;
      height: $numbered-link-container-small;
    }

    @media (--min-width-medium) {
      width: $numbered-link-container-medium;
      height: $numbered-link-container-medium;
    }

    @media (--min-width-xxlarge) {
      width: $numbered-link-container-xxlarge;
      height: $numbered-link-container-xxlarge;
    }

    :global(html.ford) & {
      width: 130px;
      height: 130px;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 2px;
      border: solid 0.5px rgb(186 186 186 / 25%);

      :global(html.discord) & {
        border: none;
      }
    }
  }

  .infoContainerWrapper {
    position: relative;
    width: 65%;
    padding-left: 0;

    @media (--min-width-xsmall-1) {
      width: 75%;
    }

    @media (--min-width-xlarge) {
      width: 70%;
    }

    @media (--min-width-xxlarge) {
      width: 72%;
    }

    :global(html.ford) & {
      width: 65%;
    }

    /* Guide Item Title */
    .headerText {
      color: var(--secondary-color-5);
      font-weight: bold;
      font-size: var(--font-size-2);
      line-height: 19px;
      padding-left: 12px;
      height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      [data-darkmode='true'] & {
        color: var(--white);
      }

      @media (--min-width-small) {
        padding-left: 22px;
      }

      @media (--min-width-medium) {
        padding-left: 10px;
      }

      @media (--min-width-large) {
        padding-left: 10px;
      }

      @media (--min-width-xxlarge) {
        padding-left: 15px;
      }

      :global(html.ford) & {
        margin-top: 1rem;
        padding-left: 1rem;
        font-size: var(--font-size-3);
      }
    }

    /* Guide Item Description */
    .descriptionText,
    .clampedDescriptionText {
      margin-top: 4px;
      font-size: var(--font-size-1);
      color: var(--secondary-color-5);

      [data-darkmode='true'] & {
        color: var(--white);
      }

      :global(html.discord) & {
        color: var(--white);
        opacity: 0.7;
      }
      /*
        use pixels for the line height so that ClampedText truncates
        the text correctly in FF, IE and Edge:

        https://github.com/CezaryDanielNowak/React-dotdotdot/issues/10
      */
      & {
        line-height: 19px;
      }

      :global(html.ford) & {
        font-size: var(--font-size-2);
        line-height: 20px;
      }
    }

    .clampedDescriptionText {
      padding-left: 12px;

      @media (--max-width-small) {
        line-height: 17px;
      }

      @media (--min-width-small) {
        padding-left: 22px;
      }

      @media (--min-width-medium) {
        padding-left: 10px;
      }

      @media (--min-width-large) {
        padding-left: 10px;
      }

      @media (--min-width-xxlarge) {
        padding-left: 15px;
      }

      :global(html.ford) & {
        padding-left: 1rem;
      }
    }
  }
}
