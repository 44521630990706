.container {
  position: relative;
  display: flex;
  align-items: center;
}

.menu {
  font-weight: bold;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
}

.dot {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.dot,
.dot::before,
.dot::after {
  width: 4px;
  height: 4px;
  border-radius: 8px;
  background-color: var(--cloud-grey);
  content: '';

  [data-darkmode='true'] & {
    background-color: var(--white);
  }
}

.dot::before,
.dot::after {
  position: absolute;
}

.dot::before {
  right: 7px;
}

.dot::after {
  left: 7px;
}

.popover {
  right: 40px;
  min-width: 160px;
}

.popoverContent {
  width: 100%;
  background-color: var(--content-area-background-color-hex);

  [data-darkmode='true'] & {
    background-color: var(--secondary-color-5);
    filter: drop-shadow(0 0 1px var(--elephant-grey));
  }
}

.popoverPointer {
  right: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  background-color: var(--white);

  [data-darkmode='true'] & {
    background-color: var(--secondary-color-5);
  }
}
